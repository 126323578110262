import { Badge, Button, Col, Image, Layout, Menu, Modal, notification, Popover, Row } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import MenuItem from 'antd/es/menu/MenuItem';
import { getToken, onMessage } from 'firebase/messaging';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { SvgSosFilled } from './components/custom-svg';
import { MENU_MAP } from './core/constant';
import { GenerateSvgIconBaseOnName } from './core/nvm-utils';
import { messaging } from './firebase/firebase-config';
import Api from './network/api';
import { HTTP_METHOD } from './network/httpMethod';
import { MEDIA_TYPE } from './network/mediaType';
import { ApiHandler } from './network/network-manager';
import { updateCurrentRoute } from './redux/cookie-slice';
import { menuList } from './redux/menu-slice';
import { fullName, roleName, roleType, sosCount, updateSos } from './redux/profile-slice';
import CustomPath from './routes/custom-path';

function App() {
    const logo = require('./asset/Logo.png');
    const profile = require('./asset/profile.png');
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const menuAccessList = useSelector(menuList)
    // const shipperPendingCount = useSelector(approvalCount);
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);
    const [count, setCount] = useState({});
    const userName = useSelector(fullName)
    const userRoleType = useSelector(roleType)
    const userRoleName = useSelector(roleName)
    const reduxSosCount = useSelector(sosCount)


    useEffect(() => {
        dispatch(updateCurrentRoute(location.pathname))
        fetchCount()
        document.body.style.zoom = "85%";
        requestPermission()
        const showNotification = onMessage(messaging, (payload) => {
            openNotification(payload)
        })
        return () => {
            showNotification()
        }
    }, [location.pathname])

    const requestPermission = () => { //permission for station manager
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                getToken(messaging, { vapidKey: 'BLE35cNsjuPkj-AzZFHGnm7quZB18oXcZXQtiei6HQzldyXZqXN_VrqJ7AV2OhVtGlxybdPmb-jd-5SWSMj-F9E' })

                    .then((currentToken) => {
                        if (currentToken) {
                            // console.log(currentToken);
                        } else {
                            console.log('No registration token available. Request permission to generate one.');
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err);
                    });
            } else if (permission === 'denied') {
                console.log("You denied for the notification");
            }
        })
    }

    const openNotification = (data) => {

        const key = `open${Date.now()}`;
        const btn = (
            <Button type="primary" onClick={() => {
                navigate(CustomPath.sos)
                notification.destroy()
            }}>
                View Sos
            </Button>
        );
        notification.open({
            message: data.notification.title,
            description: data.notification.body,
            btn,
            key,
            duration: 0
        });
    };

    const confirmLogout = () => {
        setLogoutModalOpen(false)
        navigate(CustomPath.logout)
    }

    const fetchSosCount = async () => {
        try {
            const response = await ApiHandler({ url: Api.sos_count, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                dispatch(updateSos(response.count))
                // setTotalSosCount(response.count)
            }
        } catch (error) {
        }
    }

    const fetchCount = async () => {
        try {
            const response = await ApiHandler({ url: Api.shipper_count, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                setCount({
                    pendingCount: response.pendingCount,
                    approvedCount: response.settlementReadyCount
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Modal
                title="Logout Confirmation"
                centered
                open={logoutModalOpen}
                onOk={() => confirmLogout()}
                onCancel={() => setLogoutModalOpen(false)}
                okText="Logout"
                cancelText="Cancel"
            >
                <p>Are you sure to log out?</p>
            </Modal>
            <Layout>
                <div>
                    <div
                        style={{
                            height: "70px",
                            display: 'flex',
                            alignItems: 'center',
                            position: 'fixed',
                            left: 0,
                            right: 0,
                            top: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            background: '#ffffff',
                            zIndex: 2,
                            boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Row style={{ width: '100%', paddingLeft: 20 }}>
                            <Col lg={0} sm={3} xs={3}></Col>
                            <Col lg={12} sm={18} xs={18} className='logo-center'>
                                <Link to={CustomPath.dashboard}>
                                    <Image
                                        width={180}
                                        src={logo}
                                        preview={false}
                                    />
                                </Link>
                            </Col>
                            <Col lg={12} sm={0} xs={0} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                {
                                    reduxSosCount !== 0 ?
                                        <div
                                            style={{ marginRight: 10 }}
                                        >
                                            <Badge count={reduxSosCount} >
                                                <Link to={CustomPath.sos}>
                                                    <div
                                                        style={{ width: 36, padding: 6, height: 34, borderRadius: 10, backgroundColor: 'red', textAlign: 'center' }}
                                                    >
                                                        <SvgSosFilled width={20} height={20} color={"#fff"} />
                                                    </div>
                                                </Link>
                                            </Badge>
                                        </div>
                                        :
                                        <></>
                                }

                                <div
                                    style={{ marginRight: 15, textAlign: 'right' }}
                                >
                                    <span style={{ fontSize: 18, fontWeight: '600' }}>{userName}</span><br />
                                    <span style={{ fontSize: 15, fontWeight: '600', color: 'gray' }}>{userRoleName}</span>
                                </div>

                                <Popover
                                    placement="bottomRight"
                                    title={"Profile"}
                                    overlayStyle={{ width: 300 }}
                                    content={<Button type='primary' size='large' style={{ borderRadius: 10, width: '100%' }} onClick={() => setLogoutModalOpen(true)}>
                                        Logout
                                    </Button>} >
                                    <Image
                                        preview={false}
                                        style={{
                                            width: 45,
                                            height: 45,
                                            padding: 1,
                                            objectFit: 'cover',
                                            cursor: 'pointer',
                                            borderRadius: 30,
                                            border: '2px solid'
                                        }}
                                        src={profile} />
                                </Popover>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Layout>
                    <Sider
                        className='custom-side-bar'
                        width={240}
                    >
                        <Menu
                            style={{
                                backgroundColor: '#F8F8F8',
                                border: 'none'
                            }}
                            mode='inline'
                        >
                            {/* <Menu 
                            mode='inline'
                            sub
                            items={[
                                {
                                    key: 'sub1-2',
                                    label: <div  className='siber-header'>Submenu</div>,
                                    children: [
                                        {
                                            key: '5',
                                            label:  <Link>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                            
                                                <span className="sidebar-text">Menu 22</span>
                                            </div>
                                        </Link>
                                        },
                                        {
                                            key: '6',
                                            label: 'Option 6',
                                        },
                                    ]
                                }]}
                                /> */}

                            {
                                menuAccessList && menuAccessList.map((menuAccess, index) => {
                                    const access = MENU_MAP[menuAccess.menu]
                                    if (access && access.route && access.header) {
                                        if (index === 0) {
                                            return <>
                                                <div className='siber-header'>{access.header}</div>
                                                <MenuItem key={index} className='sidebar-item'>
                                                    <Link to={access.route}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {GenerateSvgIconBaseOnName(access.name)}
                                                            <span className="sidebar-text">{access.name}</span>
                                                        </div>
                                                    </Link>
                                                </MenuItem>
                                            </>
                                        } else {
                                            const previewMenu = menuAccessList[index - 1]
                                            const previousMenuAccess = MENU_MAP[previewMenu.menu]
                                            if (previousMenuAccess) {
                                                if (previousMenuAccess.header === access.header) {
                                                    return <MenuItem key={index} className='sidebar-item'>
                                                        <Link to={access.route}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {GenerateSvgIconBaseOnName(access.name)}
                                                                <span className="sidebar-text" style={{ marginRight: access.name === 'Shipper Approval' || access.name === 'Settlement' ? 20 : 0 }}>{access.name}</span>
                                                                {
                                                                    access.name === 'Shipper Approval' ?
                                                                        <Badge count={count?.pendingCount || 0} style={{ backgroundColor: '#c2002f' }} />
                                                                        : <></>
                                                                }
                                                                {
                                                                    access.name === 'Settlement' ?
                                                                        <Badge count={count?.approvedCount || 0} style={{ backgroundColor: '#c2002f' }} />
                                                                        : <></>
                                                                }
                                                            </div>
                                                        </Link>
                                                    </MenuItem>
                                                } else {
                                                    if (menuAccess.menu === 'PRICING_GROUP') {
                                                        return <>
                                                            <div className='siber-header'>{access.header}</div>
                                                            <MenuItem key={40} className='sidebar-item'>
                                                                <Link
                                                                    to={{
                                                                        pathname: CustomPath.normal_pricing_group
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">Default Pricing</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                            <MenuItem key={41} className='sidebar-item'>
                                                                <Link
                                                                    to={{
                                                                        pathname: CustomPath.publishing_house_pricing_group
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">Publishing House Pricing</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                            <MenuItem key={42} className='sidebar-item'>
                                                                <Link
                                                                    to={{
                                                                        pathname: CustomPath.ninja_point_pricing_group
                                                                    }}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">Ninja Point Pricing</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                        </>
                                                    } else {
                                                        return <>
                                                            <div className='siber-header'>{access.header}</div>
                                                            <MenuItem key={index} className='sidebar-item'>
                                                                <Link to={access.route}>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {GenerateSvgIconBaseOnName(access.name)}
                                                                        <span className="sidebar-text">{access.name}</span>
                                                                    </div>
                                                                </Link>
                                                            </MenuItem>
                                                        </>
                                                    }

                                                }
                                            }
                                        }
                                    }

                                })
                            }
                            {
                                userRoleType === 'ADMIN' || userRoleType === 'BUSINESS_ADMIN' ?
                                    <>
                                        <MenuItem key={100} className='sidebar-item'>
                                            <Link to={CustomPath.update_order_status}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {GenerateSvgIconBaseOnName('admin')}
                                                    <span className="sidebar-text">Dynamic Order Status Update</span>
                                                </div>
                                            </Link>
                                        </MenuItem>
                                        {/* <MenuItem key={101} className='sidebar-item'>
                                            <Link to={CustomPath.ticket_repair_filter}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {GenerateSvgIconBaseOnName('admin')}
                                                    <span className="sidebar-text">Repair Ticket</span>
                                                </div>
                                            </Link>
                                        </MenuItem> */}
                                    </>
                                    :
                                    <></>
                            }
                        </Menu>
                    </Sider>
                    <Layout>
                        <Content className="custom-content">
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            </Layout >

        </>
    );
}

export default App;
