import { compose } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import { LOC_authenticate } from "../core/custom-local-storage";
import withRouter from "../network/with-router";
import { AdminActivityPage, AdminForm, AdminPage, AdminUpdate, AdvanceOrderForm, AnnouncementPage, AWBPage, BannerCreate, BannerPage, BannerUpdate, BusGatePage, BusGateSetup, BusGateUpdate, CoreConfigForm, CoreConfigPage, CoreConfigUpdate, CoverageForm, CoveragePage, CoverageUpdate, DashboardPage, DivisionPage, DriverAwbInventoryHistory, DriverAwbInventoryPage, DriverForm, DriverLocationLogsPage, DriverLocationMapbox, DriverPage, DriverUpdate, FaqDragAndDropSorting, FAQForm, FAQPage, FAQUpdate, InsuranceReport, LoginPage, LogoutPage, LogPage, LoyaltyShopForm, LoyaltyShopPage, LoyaltyShopUpdate, ManageBusGateLocation, ManageHomeDeliveryLocation, NearbyForm, NearbyPage, NearbyUpdate, NinjaPointPricingGroupPage, NormalPricingGroupPage, NotFound, NotificationChannelForm, NotificationChannelPage, NotificationChannelUpdate, NotificationHistory, OrderDetail, OrderDetailUpdate, OrderHistory, OrderPriceUpdate, ParcelPickupPage, PartnershipAdminForm, PartnershipAdminPage, PartnershipAdminUpdate, PartnershipStoreForm, PartnershipStorePage, PartnershipStoreUpdate, PreviewAndPrintAwb, PricingGroupZoneSameCityPriceSetup, ProductCategoryForm, ProductCategoryPage, ProductCategoryUpdate, PublishingHousePricingGroupPage, PushNotification, RedirectLogin, RedisCachePage, RoleForm, RolePage, RoleUpdate, RomRequest, RtsOrderPage, Settlement, ShipperActivityPage, ShipperApprovalPage, ShipperForm, ShipperLoyaltyPoint, ShipperPage, ShipperRequestApproval, ShipperRequestSupport, ShipperUpdate, SosPage, TownshipPage, UpdateOrderStatusPage, UserGroupForm, UserGroupPage, UserGroupUpdate, ZoneForm, ZonePage, ZoneSameCityPriceSetup, ZoneToZoneSetup, ZoneUpdate } from '../page';
import LoginFailureCachePage from "../page/cache/login-failure-cache-page";
import TicketRepairPage from "../page/ticket-repair/ticket-repair-page";
import CustomPath from "./custom-path";

class Router extends Component {

    constructor(props) {
        super(props)
        this.state = {
            menuList: this.props.menuAccessList,
            routeList: []
        }
    }

    render() {
        return (
            <Routes>
                {
                    LOC_authenticate() ?
                        <Route element={<App />}>
                            <Route path={CustomPath.dashboard} element={<DashboardPage />} />
                            <Route path={CustomPath.shipper_activity} element={<ShipperActivityPage />} />
                            {
                                this.props.menuAccessList && this.props.menuAccessList.map(menuAccess => {
                                    if (menuAccess.menu === 'CORE_CONFIG') {
                                        return <>
                                            <Route path={CustomPath.core_config} element={<CoreConfigPage />} />
                                            <Route path={CustomPath.core_config_form} element={<CoreConfigForm />} />
                                            <Route path={CustomPath.core_config_update_param} element={<CoreConfigUpdate />} />
                                        </>
                                    } else if (menuAccess.menu === 'ADMIN') {
                                        return <>
                                            <Route path={CustomPath.admin} element={<AdminPage />} />
                                            <Route path={CustomPath.admin_update_param} element={<AdminUpdate />} />
                                            <Route path={CustomPath.admin_create} element={<AdminForm />} />
                                        </>
                                    } else if (menuAccess.menu === 'SHIPPER') {
                                        return <>
                                            <Route path={CustomPath.shipper} element={<ShipperPage />} />
                                            {
                                                menuAccess.write_edit ? <Route path={CustomPath.shipper_create} element={<ShipperForm />} /> : <></>
                                            }
                                            <Route path={CustomPath.shipper_update_param} element={<ShipperUpdate />} />
                                            <Route path={CustomPath.rom_order} element={<RomRequest />} />
                                            {/* <Route path={CustomPath.shipper_create} element={<ShipperForm />} /> */}
                                        </>


                                    } else if (menuAccess.menu === 'DRIVER') {
                                        return <>
                                            <Route path={CustomPath.driver} element={<DriverPage />} />
                                            <Route path={CustomPath.driver_update_param} element={<DriverUpdate />} />
                                            <Route path={CustomPath.driver_create} element={<DriverForm />} />
                                        </>
                                    } else if (menuAccess.menu === 'PARTNERSHIP_ADMIN') {
                                        return <>
                                            <Route path={CustomPath.partnership_admin} element={<PartnershipAdminPage />} />
                                            <Route path={CustomPath.partnership_admin_update_param} element={<PartnershipAdminUpdate />} />
                                            <Route path={CustomPath.partnership_admin_create} element={<PartnershipAdminForm />} />
                                        </>
                                    } else if (menuAccess.menu === 'LOYALTY_SHOP') {
                                        return <>
                                            <Route path={CustomPath.loyalty_shop} element={<LoyaltyShopPage />} />
                                            <Route path={CustomPath.loyalty_shop_create} element={<LoyaltyShopForm />} />
                                            <Route path={CustomPath.loyalty_shop_update_param} element={<LoyaltyShopUpdate />} />
                                        </>
                                    } else if (menuAccess.menu === 'SHIPPER_LOYALTY_POINT') {
                                        return <>
                                            <Route path={CustomPath.shipper_loyalty} element={<ShipperLoyaltyPoint />} />
                                        </>
                                    } else if (menuAccess.menu === 'SHIPPER_APPROVAL') {
                                        return <>
                                            <Route path={CustomPath.shipper_approval} element={<ShipperApprovalPage />} />
                                            <Route path={CustomPath.shipper_request_detail_param} element={<ShipperRequestApproval />} />
                                        </>
                                    } else if (menuAccess.menu === 'ROLE') {
                                        return <>
                                            <Route path={CustomPath.role} element={<RolePage />} />
                                            <Route path={CustomPath.role_update_param} element={<RoleUpdate />} />
                                            <Route path={CustomPath.role_create} element={<RoleForm />} />
                                        </>
                                    } else if (menuAccess.menu === 'DIVISION_AND_TOWNSHIP') {
                                        return <>
                                            <Route path={CustomPath.division} element={<DivisionPage />} />
                                            <Route path={CustomPath.township_param} element={<TownshipPage />} />
                                        </>
                                    } else if (menuAccess.menu === 'HOME_DELIVERY') {
                                        return <><Route path={CustomPath.home_delivery_manange} element={<ManageHomeDeliveryLocation />} /></>
                                    } else if (menuAccess.menu === 'BUS_GATE_AND_POST_OFFICE') {
                                    } else if (menuAccess.menu === 'BUS_GATE_MGMT') {
                                        return <>
                                            <Route path={CustomPath.bus_gate_manage} element={<ManageBusGateLocation />} />
                                            <Route path={CustomPath.bus_gate_page} element={<BusGatePage />} />
                                            <Route path={CustomPath.bus_gate_set_up} element={<BusGateSetup />} />
                                            <Route path={CustomPath.bus_gate_update_param} element={<BusGateUpdate />} />
                                        </>
                                    } else if (menuAccess.menu === 'ZONE') {
                                        return <>
                                            <Route path={CustomPath.zone} element={<ZonePage />} />
                                            <Route path={CustomPath.zone_create} element={<ZoneForm />} />
                                            <Route path={CustomPath.zone_update_param} element={<ZoneUpdate />} />
                                        </>
                                    } else if (menuAccess.menu === 'ZONE_TO_ZONE') {
                                        return <>
                                            <Route path={CustomPath.zone_to_zone_price_setup} element={<ZoneToZoneSetup />} />
                                            <Route path={CustomPath.zone_same_city_price_setup_param} element={<ZoneSameCityPriceSetup />} />
                                        </>
                                    } else if (menuAccess.menu === 'PRICING_GROUP') {
                                        return <>
                                            <Route path={CustomPath.normal_pricing_group} element={<NormalPricingGroupPage />} />
                                            <Route path={CustomPath.publishing_house_pricing_group} element={<PublishingHousePricingGroupPage />} />
                                            <Route path={CustomPath.ninja_point_pricing_group} element={<NinjaPointPricingGroupPage />} />
                                        </>
                                    } else if (menuAccess.menu === 'BANNER') {
                                        return <>
                                            <Route path={CustomPath.banner} element={<BannerPage />} />
                                            <Route path={CustomPath.banner_create} element={<BannerCreate />} />
                                            <Route path={CustomPath.banner_update} element={<BannerUpdate />} />
                                        </>
                                    } else if (menuAccess.menu === 'COVERAGE_AND_PRICE') {
                                        return <>
                                            <Route path={CustomPath.coverage} element={<CoveragePage />} />
                                            <Route path={CustomPath.coverage_create} element={<CoverageForm />} />
                                            <Route path={CustomPath.coverage_update_param} element={<CoverageUpdate />} />
                                        </>
                                    } else if (menuAccess.menu === 'PRODUCT_CATEGORY') {
                                        return <>
                                            <Route path={CustomPath.product_category} element={<ProductCategoryPage />} />
                                            <Route path={CustomPath.product_category_create} element={<ProductCategoryForm />} />
                                            <Route path={CustomPath.product_category_update_param} element={<ProductCategoryUpdate />} />
                                        </>
                                    } else if (menuAccess.menu === 'FAQ') {
                                        return <>
                                            <Route path={CustomPath.faq} element={<FAQPage />} />
                                            <Route path={CustomPath.faq_create} element={<FAQForm />} />
                                            <Route path={CustomPath.faq_update_param} element={<FAQUpdate />} />
                                            <Route path={CustomPath.faq_sorting} element={<FaqDragAndDropSorting />} />
                                        </>
                                    } else if (menuAccess.menu === 'NOTIFICATION_CHANNEL') {
                                        return <>
                                            <Route path={CustomPath.notification_channel} element={<NotificationChannelPage />} />
                                            <Route path={CustomPath.notification_channel_create} element={<NotificationChannelForm />} />
                                            <Route path={CustomPath.notification_channel_update_param} element={<NotificationChannelUpdate />} />
                                        </>
                                    } else if (menuAccess.menu === 'PUSH_NOTIFICATION') {
                                        return <><Route path={CustomPath.push_notification} element={<PushNotification />} /></>
                                    } else if (menuAccess.menu === 'NOTIFICATION_HISTORY') {
                                        return <><Route path={CustomPath.notification_history} element={<NotificationHistory />} /></>
                                    } else if (menuAccess.menu === 'SHIPPER_ORDER') {
                                        return <><Route path={CustomPath.order_create} element={<AdvanceOrderForm />} /></>
                                    } else if (menuAccess.menu === 'ORDER_HISTORY') {
                                        return <><Route path={CustomPath.order_history} element={<OrderHistory />} />
                                            <Route path={CustomPath.order_detail_param} element={<OrderDetail />} />
                                            <Route path={CustomPath.order_detail_update_param} element={<OrderDetailUpdate />} /></>
                                    } else if (menuAccess.menu === 'BATCH_AWB_PRINT') {
                                        return <>
                                            <Route path={CustomPath.awb_batch_print} element={<AWBPage />} />
                                            <Route path={CustomPath.preview_and_print_awb} element={<PreviewAndPrintAwb />} />
                                        </>
                                    } else if (menuAccess.menu === 'NEARBY') {
                                        return <><Route path={CustomPath.nearby} element={<NearbyPage />} />
                                            <Route path={CustomPath.nearby_create} element={<NearbyForm />} />
                                            <Route path={CustomPath.nearby_update_param} element={<NearbyUpdate />} /></>
                                    } else if (menuAccess.menu === 'ORDER_ISSUE') {
                                        return <><Route path={CustomPath.request_support} element={<ShipperRequestSupport />} /></>
                                    } else if (menuAccess.menu === 'ORDER_PRICE_UPDATE') {
                                        return <><Route path={CustomPath.order_price_update} element={<OrderPriceUpdate />} /></>
                                    } else if (menuAccess.menu === 'RTS') {
                                        return <Route path={CustomPath.rts_order_page} element={<RtsOrderPage />} />
                                    }
                                    else if (menuAccess.menu === 'SETTLEMENT') {
                                        return <Route path={CustomPath.shipper_settlement} element={<Settlement />} />
                                    }
                                    else if (menuAccess.menu === 'PARCEL_PICK_UP') {
                                        return <Route path={CustomPath.parcel_pickup} element={<ParcelPickupPage />} />
                                    } else if (menuAccess.menu === 'PARCEL_INSURANCE') {
                                        return <Route path={CustomPath.insurance_report} element={<InsuranceReport />} />
                                    }
                                    else if (menuAccess.menu === 'REDIS_CACHE') {
                                        return <Route path={CustomPath.redis_cache} element={<RedisCachePage />} />
                                    } else if (menuAccess.menu === 'DRIVER_LOCATION_LOG') {
                                        return <>
                                            <Route path={CustomPath.driver_location_log} element={<DriverLocationLogsPage />} />
                                            <Route path={CustomPath.driver_location_log_detail} element={<DriverLocationMapbox />} />
                                        </>
                                    } else if (menuAccess.menu === 'ADMIN_ACTIVITY_LOG') {
                                        return <>
                                            <Route path={CustomPath.admin_activity_log} element={<AdminActivityPage />} />
                                        </>
                                    } else if (menuAccess.menu === 'DRIVER_AWB_INVENTORY') {
                                        return <>
                                            <Route path={CustomPath.inventory_driver_awb} element={<DriverAwbInventoryPage />} />
                                            <Route path={CustomPath.inventory_driver_awb_history_param} element={<DriverAwbInventoryHistory />} />
                                        </>
                                    }
                                })
                            }
                            {/* <Route path={CustomPath.loyalty_shop_report} element={<LoyaltyReport />} />
                            <Route path={CustomPath.loyalty_shop_report_history} element={<LoyaltyHistory />} /> */}
                            <Route path={CustomPath.ticket_repair_filter} element={<TicketRepairPage />} />
                            <Route path={CustomPath.update_order_status} element={<UpdateOrderStatusPage />} />
                            <Route path={CustomPath.redis_cache_login_failure} element={<LoginFailureCachePage />} />
                            <Route path={CustomPath.announcement} element={<AnnouncementPage />} />
                            <Route path={CustomPath.sos} element={<SosPage />} />
                            <Route path={CustomPath.user_group} element={<UserGroupPage />} />
                            <Route path={CustomPath.user_group_create} element={<UserGroupForm />} />
                            <Route path={CustomPath.user_group_update_param} element={<UserGroupUpdate />} />
                            <Route path={CustomPath.pricing_group_zone_same_city_price_setup} element={<PricingGroupZoneSameCityPriceSetup />} />

                            <Route path={CustomPath.partnership_store} element={<PartnershipStorePage />} />
                            <Route path={CustomPath.partnership_store_create} element={<PartnershipStoreForm />} />
                            <Route path={CustomPath.partnership_store_update_param} element={<PartnershipStoreUpdate />} />
                            <Route path={CustomPath.logs} element={<LogPage />} />
                            <Route path="/*" element={<NotFound />} />
                        </Route >
                        :
                        <Route path="/*" element={<RedirectLogin />} />
                }
                <Route path={CustomPath.app} element={<LoginPage />} />
                <Route path={CustomPath.logout} element={<LogoutPage />} />
            </Routes >

        );
    }
}
export default compose(withRouter)(Router)
