import { compose } from "@reduxjs/toolkit";
import { Col, Row, Select, Skeleton, Table, Tag } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";

class ShipperActivityPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            date_list: [],
            isDataFetching: false,
            filterDate: null
        }
    }

    componentDidMount() {
        this.fetchActivity()

        const today = dayjs();

        let date_list = []
        date_list.push(
            {
                key: 0,
                label: today.format('MMM DD, YYYY'),
                value: today.format('YYYY-MM-DD')
            }
        )
        for (let i = 1; i < 7; i++) {
            date_list.push(
                {
                    key: i,
                    label: today.subtract(i, 'day').format('MMM DD, YYYY'),
                    value: today.subtract(i, 'day').format('YYYY-MM-DD')
                }
            )
        }
        this.setState({
            date_list,
            filterDate: today.format('YYYY-MM-DD')
        })

    }

    fetchActivity = async (filterDate) => {
        this.setState({
            isDataFetching: true
        })
        const today = dayjs();
        let params = {
            date: today.format('YYYY-MM-DD')
        }
        if (filterDate) {
            params = { date: filterDate }
            this.setState({ filterDate })
        }

        try {
            const response = await ApiHandler({url: Api.shipper_activity, method:HTTP_METHOD.GET, mediaType:MEDIA_TYPE.JSON, requestParams: params})
            if (response) {
                this.setState({ data: response })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    render() {
        const { data, isDataFetching, date_list, filterDate } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{index + 1}</span>,
                width: 5
            },
            {
                title: 'Business Name',
                dataIndex: 'businessName',
                key: 'businessName',
            },
            {
                title: 'Type',
                dataIndex: 'logType',
                key: 'logType',
            }, {
                title: 'Category',
                key: 'category',
                render: (shipperActivity) => (
                    <>
                        {
                            shipperActivity.category === "null" ?
                                <>-</>
                                :
                                <>{shipperActivity.category}</>
                        }
                    </>
                ),
            }, {
                title: 'Sub-Category',
                key: 'subCategory',
                render: (shipperActivity) => (
                    <>
                        {
                            shipperActivity.subCategory === "null" ?
                                <>-</>
                                :
                                <>{shipperActivity.subCategory}</>
                        }
                    </>
                ),
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
            },

            {
                title: 'Request Status',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (shipperActivity) => (
                    <>
                        {
                            shipperActivity.status ?
                                <Tag color="green">Success</Tag>
                                :
                                <Tag color="#808080">Fail</Tag>
                        }
                    </>
                ),
            },
        ]
        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <TitleLevel3 label={"Shipper Activity"} />
                            </Col>
                            <Col span={7} />
                            <Col span={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Select
                                    size="large"
                                    className="custom-selector-gray"
                                    value={filterDate}
                                    onChange={(value) => this.fetchActivity(value)}
                                    style={{
                                        width: '100%'
                                    }}
                                    options={date_list}
                                />
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Table
                                            style={{ borderRadius: 10 }}
                                            pagination={false}
                                            columns={columns}
                                            dataSource={data}
                                            rowClassName={getRowClassName} />
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(ShipperActivityPage)