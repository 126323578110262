import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Card, Col, DatePicker, Input, Row, Select, Skeleton, Table, TreeSelect, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { NJVButton } from "../../components/core-component";
import { FormLabelOptional, TitleLevel3 } from "../../components/general-component";
import { Constant, MENU_NAME } from "../../core/constant";
import '../../css/order-history.css';
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler, downloadExcel } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import { updateDivisionList, updateTownShipList, updateTreeData } from "../../redux/location-slice";
import CustomPath from "../../routes/custom-path";
const { Option } = Select
const { RangePicker } = DatePicker;

class OrderHistory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            order_history_collection: [],
            historyMap: {},
            titleList: [],
            totalPage: 0,
            pageSize: Constant.pageSize,
            page: 1,
            filterData: {},
            orderStatusList: [],
            division_collection: [],
            from_township_collection: [],
            to_township_collection: [],
            isDataFetching: false,
        }
    }

    componentDidMount() {
        this.fetchOrderStatus()
        this.fetchDivision()
        let urlParam = new URLSearchParams(window.location.search);
        let urlPage = urlParam.get('page');
        if (urlPage) {
            const currentUrl = window.location.href;
            const index = currentUrl.indexOf('?');
            const searchParams = new URLSearchParams(currentUrl.substring(index))
            let filterData = {};

            for (let [key, value] of searchParams.entries()) {
                if (key !== 'page' && value !== null && value !== undefined && value !== 'null' && value !== 'undefined') {
                    filterData[key] = value;
                }
            }
            if (filterData?.fromDate && filterData?.toDate) {
                filterData = { ...filterData, fromDate: dayjs(filterData?.fromDate), toDate: dayjs(filterData?.toDate) }
            }
            if (filterData['fromDate-toDate']) {
                filterData['fromDate-toDate'] = [dayjs(filterData?.fromDate), dayjs(filterData?.toDate)];
            }

            this.setState({
                filterData
            }, () => this.fetchData(urlPage))
        } else {
            urlPage = 1;
            urlParam.set('page', urlPage);
            const currentUrl = new URL(window.location.href);
            const newUrl = `${currentUrl.pathname}?${urlParam.toString()}`;
            window.history.replaceState({}, '', newUrl);
            this.fetchData(urlPage);
        }
    }

    fetchData = async (pageNumber) => {
        let { pageSize, page, filterData } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            let params = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize
            }
            if (filterData) {
                if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate > filterData.toDate) {
                    message.error("Invalid date")
                    return
                }

                if (filterData.fromDate) {
                    filterData = { ...filterData, 'fromDate': filterData.fromDate.format("YYYY-MM-DD") }
                }
                if (filterData.toDate) {
                    filterData = { ...filterData, 'toDate': filterData.toDate.format("YYYY-MM-DD") }
                }
                if (filterData.senderPhoneNumber) {
                    const updatedValue = filterData.senderPhoneNumber.charAt(0) === '0' ? filterData.senderPhoneNumber.slice(1) : filterData.senderPhoneNumber;
                    filterData = { ...filterData, senderPhoneNumber: updatedValue }
                }
                if (filterData.recipientPhoneNumber) {
                    const updatedValue = filterData.recipientPhoneNumber.charAt(0) === '0' ? filterData.recipientPhoneNumber.slice(1) : filterData.recipientPhoneNumber;
                    filterData = { ...filterData, recipientPhoneNumber: updatedValue }
                }
                if (!(filterData.fromDivisionTownship && filterData.toDivisionTownship)) {
                    filterData = { ...filterData, fromDivision: null, fromTownshipId: null, toDivision: null, toTownshipId: null }
                }

                if (filterData['fromDate-toDate']) {
                    filterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
                } else {
                    filterData = { ...filterData, 'fromDate': null, 'toDate': null }
                }

                // const data2 = { toTownshipId : filterData.toTownshipId}

                params = {
                    ...params,
                    ...filterData,
                    //    toTownshipId : filterData?.toTownshipId,
                    //    fromTownshipId : filterData?.fromTownshipId,
                    page: filterData.page ? parseInt(filterData.page) - 1 : params.page,
                }
            }
            const response = await ApiHandler({ url: Api.order_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: params })
            if (response && response.content) {

                let updatedList = []

                response.content?.map((orderHistory) => {
                    orderHistory = { ...orderHistory, key: orderHistory.id }
                    if (orderHistory.orderModifiedDate) {
                        const orderModifiedDate = dayjs(orderHistory.orderModifiedDate, 'YYYY-MM-DD HH:mm:ss');
                        orderHistory = { ...orderHistory, orderModifiedDate: orderModifiedDate.format('DD MMM, YYYY hh:mm A') }
                    }
                    updatedList.push(orderHistory)
                })
                this.setState({
                    data: updatedList,
                    totalPage: response.totalElements,
                    page: pageNumber ? pageNumber : page,
                    isDataFetching: false,
                    isSearchingData: false,
                })
            }
        } catch (error) {
            this.setState({
                isSearchingData: false
            })
        }
    }

    fetchOrderStatus = async () => {
        try {
            var requestParams = {
                'enumType': 'ORDER_STATUS'
            }
            const response = await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let temp = []
                response.map((res, index) => {
                    temp.push({
                        key: res.key,
                        value: res.key,
                        label: res.value,
                    });
                })
                this.setState({
                    orderStatusList: temp
                })
            }
        } catch (error) {

        }
    }

    fetchDivision = async () => {
        await ApiHandler({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                let township_map = {}
                let division_collection = []

                division_collection = response

                this.props.dispatch(updateDivisionList(response));

                division_collection?.map(division => {
                    township_map[division.id] = division.townshipDTOList
                })

                this.props.dispatch(updateTownShipList(township_map))

                const treeData = response.map(division => ({
                    title: division.name,
                    value: `division-${division.id}`,
                    selectable: false,
                    children: division.townshipDTOList.map(township => ({
                        title: township.name,
                        value: `division-${division.id}-township-${township.id}`
                    }))
                }))

                this.props.dispatch(updateTreeData(treeData))

            })
            .catch((error) => {
            })
    }

    search = () => {
        let { filterData } = this.state;
        const searchParams = new URLSearchParams()

        if (filterData) {
            searchParams.append('page', 1);

            if (filterData.fromDate) {
                filterData = { ...filterData, fromDate: filterData.fromDate.format("YYYY-MM-DD") }
            }
            if (filterData.toDate) {
                filterData = { ...filterData, toDate: filterData.toDate.format("YYYY-MM-DD") }
            }

            if (filterData['fromDate-toDate']) {
                filterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
            } else {
                filterData = { ...filterData, 'fromDate': null, 'toDate': null }
            }

            if (!filterData.fromDivisionTownship) {
                filterData = { ...filterData, fromDivision: null, fromTownshipId: null }
            }
            if (!filterData.toDivisionTownship) {
                filterData = { ...filterData, toDivision: null, toTownshipId: null }
            }

            for (let key in filterData) {
                if (key !== 'page' && filterData[key] !== null && filterData[key] !== undefined && filterData[key] !== 'null' && filterData[key] !== 'undefined' && filterData[key] !== '') {
                    searchParams.append(key, filterData[key])
                }
            }
        }

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.search = searchParams.toString();
        this.setState({
            isSearchingData: true
        })
        this.fetchData(1)

        window.history.pushState({}, '', url)
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        let { township_map } = this.props;

        filterData = {
            ...filterData,
            [key]: value
        }
        // let trackingList = []
        // if(key === 'trackingNumber' && value){
        //     const partValue = value?.split(',')
        //     if(partValue){
        //         trackingList.push(partValue)
        //     }
        // }
        if (key === 'fromDivision') {
            filterData = { ...filterData, fromTownship: null }
            this.setState({
                from_township_collection: township_map[value]
            })
        } else if (key === 'toDivision') {
            filterData = { ...filterData, toTownship: null }
            this.setState({
                to_township_collection: township_map[value]
            })
        } else if (key === 'fromDate-toDate' && value) {
            filterData = { ...filterData, fromDate: value[0], toDate: value[1], 'fromDate-toDate': value }
            this.setState({
                filterData
            })
        } else if (key === 'fromDivisionTownship' && value) {
            const partValue = value.split('-');
            const fromDivision = partValue[1]
            const fromTownshipId = partValue[3]
            filterData = { ...filterData, fromDivision, fromTownshipId, 'fromDivisionTownship': value }
            this.setState({
                filterData
            })
        } else if (key === 'toDivisionTownship' && value) {
            const partValue = value.split('-');
            const toDivision = partValue[1]
            const toTownshipId = partValue[3]
            filterData = { ...filterData, toDivision, toTownshipId, 'toDivisionTownship': value }
            this.setState({
                filterData
            })
        }
        this.setState({
            filterData
        })
    }

    pagination = (page, size) => {
        const urlParam = new URLSearchParams(window.location.search);
        const urlPage = urlParam.get('page');
        let newUrl;
        if (urlPage) {
            const currentUrl = window.location.href;
            let replace_url = currentUrl.toString();

            newUrl = replace_url.replace(/page=\d+/, `page=${page}`);
        } else {
            urlParam.set('page', page);
            const currentUrl = new URL(window.location.href);
            newUrl = `${currentUrl.pathname}?${urlParam.toString()}`;
        }

        window.history.pushState({}, '', newUrl)
        this.fetchData(page)
        this.setState({
            page: page - 1
        })
    }

    downloadShipperOrderExcel = async () => {
        let { filterData, page, pageSize } = this.state
        try {
            let params = {
                page: page - 1,
                size: pageSize
            }
            if (filterData) {
                if ((filterData.fromDate && !filterData.toDate) || (!filterData.fromDate && filterData.toDate)) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate > filterData.toDate) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate) {
                    filterData = { ...filterData, 'fromDate': filterData.fromDate.format("YYYY-MM-DD") }
                }
                if (filterData.toDate) {
                    filterData = { ...filterData, 'toDate': filterData.toDate.format("YYYY-MM-DD") }
                }
                if (filterData['fromDate-toDate']) {
                    filterData['fromDate-toDate'] = null;
                } else {
                    filterData = { ...filterData, 'fromDate': null, 'toDate': null }
                }
                if (filterData.senderPhoneNumber) {
                    const updatedValue = filterData.senderPhoneNumber.charAt(0) === '0' ? filterData.senderPhoneNumber.slice(1) : filterData.senderPhoneNumber;
                    filterData = { ...filterData, senderPhoneNumber: updatedValue }
                }
                if (filterData.recipientPhoneNumber) {
                    const updatedValue = filterData.recipientPhoneNumber.charAt(0) === '0' ? filterData.recipientPhoneNumber.slice(1) : filterData.recipientPhoneNumber;
                    filterData = { ...filterData, recipientPhoneNumber: updatedValue }
                }
                params = {
                    ...params,
                    ...filterData
                }
            }
            await downloadExcel(Api.order_download_execel, params, "Parcel List")
        } catch (error) {
        }
    }

    // routToDetail = (id) => {
    //     let { page, filterData } = this.state

    //     if (filterData.fromDate) {
    //         filterData = { ...filterData, fromDate: filterData.fromDate.format("YYYY-MM-DD") }
    //     }
    //     if (filterData.toDate) {
    //         filterData = { ...filterData, toDate: filterData.toDate.format("YYYY-MM-DD") }
    //     }
    //     if (filterData['fromDate-toDate']) {
    //         filterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
    //     }
    //     const searchParams = new URLSearchParams();
    //     for(let key in filterData){
    //         if (filterData[key] !== null && filterData[key] !== undefined && filterData[key] !== 'null' && filterData[key] !== 'undefined' && filterData[key] !== '') {
    //             searchParams.append(key, filterData[key]);
    //         }
    //     }

    //     const currentUrl = `${CustomPath.order_history}?${searchParams.toString()}`

    //     const data = {
    //         route: currentUrl
    //     }
    //     this.props.navigate(`${CustomPath.order_detail}/${id}`, { state: { data } })
    // }

    render() {
        const { isDataFetching, isSearchingData, pageSize, totalPage, page, data, orderStatusList, filterData, } = this.state
        const currentSearchUrl = window.location.search;

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Tracking Number',
                dataIndex: 'trackingNumber',
                key: 'trackingNumber',
                width: 150
            },
            {
                title: 'Pickup  Date',
                dataIndex: 'pickupDate',
                key: 'pickupDate',
            },
            {
                title: 'Order Type',
                key: 'orderType',
                render: (orderHistory) => (
                    <>
                        <div>{orderHistory.orderType}</div>
                        <div>{orderHistory.paymentOption}</div>
                    </>
                ),
                width: 180
            },
            {
                title: 'COD',
                key: 'codAmount',
                render: (orderHistory) => (
                    <div>{orderHistory.codAmount ? orderHistory.codAmount : 0}</div>
                ),

            },
            {
                title: 'Order Status',
                dataIndex: 'ninjaCoreOrderStatus',
                key: 'ninjaCoreOrderStatus',
                width: 20
            },
            {
                title: 'Update Info',
                key: 'modifiedUserInfo',
                render: (orderHistory) => (
                    <>
                        <div>{orderHistory.orderModifiedUserName}</div>
                        <div>{orderHistory.orderModifiedDate}</div>
                    </>
                )
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 10,
                render: (orderHistory) => (
                    <>
                        <Link to={`${CustomPath.order_detail}/${orderHistory.id}`} state={{ data: { id: orderHistory.id, route: `${CustomPath.order_history}${currentSearchUrl}` } }}>
                            <Button size={'middle'} type="primary" shape="circle" icon={<EyeOutlined />} style={{ marginBottom: 5 }} />
                        </Link><br />
                        <Link to={`${CustomPath.order_detail_update}/${orderHistory.id}`} state={{ data: { id: orderHistory.id, route: `${CustomPath.order_history}${currentSearchUrl}` } }}>
                            <NJVButton size={'middle'} type="primary" shape="circle" icon={<EditOutlined />} menuName={MENU_NAME.ORDER_HISTORY} />
                        </Link>
                    </>
                ),
            }
        ]
        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <TitleLevel3 label={"Parcel History"} />
                            <br />
                        </Col>
                        <Col span={24}>
                            <Card
                                style={{ backgroundColor: "#f5f5f5", boxShadow: 'none', paddingBlock: 15 }}
                                bordered={false}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col span={6}>
                                        <FormLabelOptional label="Tracking Number" />
                                        <Input size="large" placeholder={"Tracking Number"} value={filterData?.trackingNumber} onChange={(event) => this.updateFilterData('trackingNumber', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="Created Date(from-to)" />
                                        <RangePicker size="large" style={{ width: '100%' }}
                                            allowClear
                                            value={filterData['fromDate-toDate']}
                                            onChange={(value) => this.updateFilterData('fromDate-toDate', value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="Order Status" />
                                        <Select
                                            value={filterData.orderStatus}
                                            onChange={(value) => this.updateFilterData('orderStatus', value)}
                                            size="large"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Select order status">
                                            {
                                                orderStatusList.map(status => {
                                                    return <Option key={status.value} value={status.value}>{status.label}</Option>
                                                })
                                            }
                                        </Select>
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="Receiver Name" />
                                        <Input size="large" value={filterData.recipientName} placeholder={"Receiver Name"} onChange={(event) => this.updateFilterData('recipientName', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="Recipient Phone Number" />
                                        <Input size="large" value={filterData.recipientPhoneNumber} placeholder={"Receiver Phone Number"} onChange={(event) => this.updateFilterData('recipientPhoneNumber', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="Sender Name" />
                                        <Input size="large" value={filterData.senderName} placeholder={"Sender Name"} onChange={(event) => this.updateFilterData('senderName', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="Sender Phone Number" />
                                        <Input size="large" value={filterData.senderPhoneNumber} placeholder={"Sender Phone Number"} onChange={(event) => this.updateFilterData('senderPhoneNumber', event.target.value)} />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="From Township" />
                                        <TreeSelect
                                            style={{
                                                width: '100%',
                                            }}
                                            value={filterData.fromDivisionTownship}
                                            allowClear
                                            size="large"
                                            dropdownStyle={{
                                                maxHeight: 400,
                                                overflow: 'auto',
                                            }}

                                            treeData={this.props.treeData}
                                            placeholder="Select from Township"
                                            onChange={(value) => this.updateFilterData('fromDivisionTownship', value)}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="To Township" />
                                        <TreeSelect
                                            style={{
                                                width: '100%',
                                            }}
                                            value={filterData.toDivisionTownship}
                                            allowClear
                                            size="large"
                                            dropdownStyle={{
                                                maxHeight: 400,
                                                overflow: 'auto',
                                            }}
                                            treeData={this.props.treeData}
                                            placeholder="Select to Township"
                                            onChange={(value) => this.updateFilterData('toDivisionTownship', value)}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="&nbsp;" />
                                        <Button
                                            size="large"
                                            style={{ width: '100%' }}
                                            loading={isSearchingData}
                                            type="primary" onClick={() => this.search()}>
                                            Search
                                        </Button>
                                    </Col>
                                    <Col span={6}>
                                        <FormLabelOptional label="&nbsp;" />
                                        <Button size="large"
                                            onClick={() => this.downloadShipperOrderExcel()} type="primary" style={{ width: '100%', backgroundColor: 'green' }}>Export Excel</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        {
                            isDataFetching ?
                                <Col span={24}>
                                    <Skeleton active />
                                </Col>
                                :
                                <Col span={24}>
                                    <Table
                                        style={{ borderRadius: 10 }}

                                        pagination={{
                                            position: 'top' | 'bottom',
                                            total: totalPage,
                                            current: page,
                                            onChange: this.pagination,
                                            defaultPageSize: pageSize,
                                            showSizeChanger: false
                                        }}
                                        columns={columns}
                                        dataSource={data}
                                        rowClassName={getRowClassName}
                                    />
                                </Col>

                        }
                    </Row>
                </Col>
            </Row >
        )
    }
}

export default compose(withRouter)(OrderHistory);