import { compose } from "@reduxjs/toolkit";
import { Button, Card, Checkbox, Col, Input, List, Row, Skeleton, message, DatePicker } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { TitleLevel3 } from "../../components/general-component";
import { Colors } from "../../components/theme";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
const { RangePicker } = DatePicker
class AWBPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            totalPage: 0,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            data: [],
            selected_order: [],
            selected_order_map: new Map(),
            isDataFetching: false,
            trackingNumberList: [],
            trakingInputValue: '',
            isLoadMore: false,
            isCheckAll: false,
            isCheckSpecific: false,
            filterData: {}
        }
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
    }

    // onPasteTrackingNumber = (event) => {
    //     const { trackingNumberList } = this.state
    //     const pastedText = event.clipboardData.getData('text');
    //     const sanitizedText = pastedText.replace(/\r?\n/g, ',');
    //     const substrings = sanitizedText.split(',');
    //     const trimmedSubstrings = substrings.map((substring) => substring.trim());
    //     this.setState({
    //         trackingNumberList: [...trackingNumberList, ...trimmedSubstrings],
    //     })
    //     this.inputRef.current.setFieldsValue({ searchBox: '' })

    // }

    onPressEnter = (event) => {
        const { trackingNumberList } = this.state
        if (event.code === 'Enter') {

            const sanitizedText = event.target.value.replace(/\r?\n/g, ',');
            const substrings = sanitizedText.split(' ');
            const trimmedSubstrings = []
            substrings.map((substring) => {
                let tValue = substring.trim()
                if (tValue !== undefined && tValue !== '' && tValue.length > 8) {
                    trimmedSubstrings.push(tValue)
                }
            })
            this.setState({
                trackingNumberList: [...trackingNumberList, ...trimmedSubstrings],
            })
            this.inputRef.current.setFieldsValue({ searchBox: '' })

        }
    }

    removeFromList = (trackingNumber) => {
        const { trackingNumberList } = this.state
        let newList = []
        trackingNumberList.map(t => {
            if (t !== trackingNumber) {
                newList.push(t)
            }
        })
        this.setState({
            trackingNumberList: newList,
        })
    }

    fetchData = async (pageNumber) => {
        let { pageSize, page, filterData } = this.state
        this.setState({
            isDataFetching: true
        })
        let formattedPhoneNo;
        if (filterData?.senderPhoneNumber?.startsWith('0')) {
            formattedPhoneNo = filterData?.senderPhoneNumber?.substring('1')
        }
        try {
            // filterData['date-range-picker'] = null
            let requestParams = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize,
                businessName: filterData?.businessName ? filterData.businessName : null,
                fromDate: filterData?.fromDate ? filterData?.fromDate.format("YYYY-MM-DD") : null,
                toDate: filterData?.toDate ? filterData?.toDate.format("YYYY-MM-DD") : null,
                senderPhoneNumber: formattedPhoneNo ? formattedPhoneNo : filterData?.senderPhoneNumber,
                orderStatus: 'PENDING_PICK_UP'
            }
            const response = await ApiHandler({ url: Api.order_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            let updatedList = []
            response.content && response.content.map(order => {
                updatedList.push(order)
            })
            this.setState({
                page: pageNumber ? pageNumber : page,
                totalPagination: response.totalElements,
                data: updatedList
            })

        } catch (error) {

        }
        this.setState({
            isDataFetching: false
        })
    }

    search = () => {
        this.fetchData(1)
    }

    fetchPagination = async (pageNumber) => {
        let { pageSize, page, data, filterData } = this.state
        this.setState({
            isLoadMore: true
        })
        let formattedPhoneNo;
        if (filterData?.senderPhoneNumber?.startsWith('0')) {
            formattedPhoneNo = filterData?.senderPhoneNumber?.substring('1')
        }
        try {
            let requestParams = {
                page: pageNumber ? pageNumber - 1 : page - 1,
                size: pageSize,
                ...filterData,
                senderPhoneNumber: formattedPhoneNo,
                orderStatus: 'PENDING_PICK_UP'
            }
            const response = await ApiHandler({ url: Api.order_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            let isSelectedOne = false
            data && data.map(order => {
                if (order.isSelected) {
                    isSelectedOne = true
                    return
                }
            })

            let updatedList = []
            response.content && response.content.map(order => {
                const orderTime = dayjs(order.createdDate, 'YYYY-MM-DD HH:mm:ss');
                if (orderTime) {
                    order = { ...order, createdDate: orderTime.format('DD MMM,YYYY hh:mm A') }
                }
                updatedList.push(order)
            })

            this.setState({
                page: pageNumber ? pageNumber : page,
                totalPagination: response.totalElements,
                data: [...data, ...updatedList],
                isCheckAll: false,
                isCheckSpecific: isSelectedOne


            })
        } catch (error) {
        }
        this.setState({
            isLoadMore: false
        })
    }

    loadMore = () => {
        const { page } = this.state
        this.fetchPagination(page + 1)
    }

    searchAllTracking = async () => {
        const { trackingNumberList } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            let requestParams = {
                page: 0,
                size: 50,
                orderStatus: 'PENDING_PICK_UP',
                trackingNumbers: trackingNumberList.join(',')
            }
            const response = await ApiHandler({ url: Api.order_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            this.setState({
                page: 1,
                totalPagination: response.totalElements,
                data: response.content
            })

        } catch (error) {

        }
        this.setState({
            isDataFetching: false
        })
    }

    pagination = (page, size) => {
        this.fetchData(page)
    }
    onSelectItem = (e, item) => {
        let { selected_order, data } = this.state
        let isCheckAll = true
        if (e.target.checked) {
            let new_data_list = []
            data.map(order => {
                if (order.trackingNumber === item.trackingNumber) {
                    order = { ...order, 'isSelected': true }
                }
                if (!order.isSelected) {
                    isCheckAll = false
                }
                new_data_list.push(order)
            })
            data = new_data_list
            selected_order.push(item)
        } else {
            let new_selected_order_list = []
            selected_order.map(order => {
                if (order.trackingNumber !== item.trackingNumber) {
                    new_selected_order_list.push(order)
                }
            })
            selected_order = new_selected_order_list

            let new_data_list = []
            data.map(order => {
                if (order.trackingNumber === item.trackingNumber) {
                    order = { ...order, isSelected: false }
                }
                new_data_list.push(order)
            })
            data = new_data_list
            isCheckAll = false
        }

        this.setState({
            selected_order: selected_order,
            data: data,
            isCheckAll: isCheckAll,
            isCheckSpecific: isCheckAll ? false : true
        })

    }

    onSelectAllItem = (e) => {
        let { selected_order, data } = this.state
        let isCheckAll = false
        let selected_order_map = {}
        selected_order.map(order => {
            selected_order_map[order.trackingNumber] = order
        })
        if (e.target.checked) {
            let newList = []
            data.map(order => {
                order = { ...order, 'isSelected': true }
                newList.push(order)
                if (!selected_order_map[order.trackingNumber]) {
                    selected_order.push(order)
                }
            })
            data = newList
            isCheckAll = true
        } else {
            let removed_tracking_map = {}
            let new_data_list = []
            data.map(order => {
                order = { ...order, isSelected: false }
                new_data_list.push(order)
                removed_tracking_map[order.trackingNumber] = order.trackingNumber
            })
            data = new_data_list
            selected_order = []
        }

        this.setState({
            selected_order: selected_order,
            data: data,
            isCheckAll: isCheckAll,
            isCheckSpecific: false
        })
    }

    previewAWB = () => {
        const { selected_order } = this.state
        if (selected_order && selected_order.length !== 0) {
            this.props.navigate(CustomPath.preview_and_print_awb, { state: { data: selected_order, route: CustomPath.awb_batch_print } })
        } else {
            message.destroy()
            message.error("Please select at least one record")
        }
    }
    updateFilterData = (key, value) => {
        let { filterData } = this.state

        filterData = {
            ...filterData,
            [key]: value
        }
        if (key && key === 'date-range-picker') {
            if (value) {
                filterData['fromDate'] = value[0]
                filterData['toDate'] = value[1]
            } else {
                filterData['fromDate'] = null
                filterData['toDate'] = null
            }
        }
        console.log(filterData)
        this.setState({
            filterData
        })
    }
    render() {
        const { data, isDataFetching, isLoadMore, selected_order, totalPagination, filterData, isCheckAll, isCheckSpecific } = this.state
        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <TitleLevel3 label={"Batch AWB Print"} />
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(event) => this.updateFilterData('businessName', event.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by business name"
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    onChange={(event) => this.updateFilterData('senderPhoneNumber', event.target.value)}
                                    style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                    placeholder="Search by phone"
                                />
                            </Col>
                            <Col span={6}>
                                <RangePicker size="large" style={{ width: '100%', background: '#f5f5f5' }}
                                    value={filterData['date-range-picker']}
                                    allowClear
                                    onChange={(value) => this.updateFilterData('date-range-picker', value)}
                                />
                            </Col>
                            <Col span={6}>
                                <Button size="large" shape="square" onClick={() => this.search()} type="primary" style={{ width: '100%' }}>Search</Button>
                            </Col>


                            {/* <Col span={24}>
                                <Form
                                    layout="vertical"
                                    ref={this.inputRef}
                                    initialValues={{ searchBox: '' }}
                                >

                                    <Form.Item name="searchBox">
                                        <Input
                                            style={{ backgroundColor: '#f1f1f1' }}
                                            onPressEnter={this.onPressEnter}
                                            placeholder="Paste or type the tracking number"
                                        />
                                    </Form.Item>
                                    <Row>
                                        <Col span={24} style={{ marginBottom: 10 }}>
                                            {
                                                trackingNumberList && trackingNumberList.map(tracking =>
                                                    <span style={{
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        background: '#f1f1f1',
                                                        width: 'fit-content',
                                                        padding: '6px 15px',
                                                        borderRadius: 100,
                                                        marginRight: 10,
                                                        marginBottom: 5
                                                    }}>
                                                        <span>{tracking}</span>
                                                        <div style={{ marginLeft: 10, marginTop: 5 }} onClick={() => this.removeFromList(tracking)}><SvgCrossOutlined width={20} height={20} color={'red'} /></div>
                                                    </span>
                                                )
                                            }
                                        </Col>
                                    </Row>

                                </Form>
                            </Col>
                            {
                                trackingNumberList && trackingNumberList.length !== 0 ?
                                    <>
                                        <Col span={5}>
                                            <Button type="primary" onClick={() => {
                                                this.fetchData()
                                                this.setState({ trackingNumberList: [] })
                                            }} >
                                                Clear All
                                            </Button>
                                            <Button type="primary" style={{ marginLeft: 20 }} onClick={() => this.searchAllTracking()} >
                                                Search
                                            </Button>
                                        </Col>
                                    </>
                                    :
                                    <>
                                    </>

                            } */}
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <Row style={{ alignItems: 'center' }}>
                                                    <Col span={12} >
                                                        <span>{selected_order.length} Selected</span> <Button type="primary" style={{ backgroundColor: Colors.primary, marginLeft: 15 }} onClick={() => this.previewAWB()}>Preview AWB</Button>
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: 'right' }}>
                                                        <Checkbox
                                                            indeterminate={isCheckSpecific}
                                                            checked={isCheckAll}
                                                            onChange={(e) => this.onSelectAllItem(e)}>
                                                            Select all
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            {
                                                data ?
                                                    <>
                                                        <Col span={24}>
                                                            <List
                                                                itemLayout="horizontal"
                                                                dataSource={data}
                                                                renderItem={(item, index) => (
                                                                    <Card
                                                                        style={{
                                                                            backgroundColor: "#f5f5f5",
                                                                            boxShadow: 'none',
                                                                            paddingLeft: 20,
                                                                            marginBottom: 10,
                                                                            borderRadius: 20,
                                                                            width: '100%',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        bordered={false}
                                                                    // onClick={() => this.onSelectItem(item.id, item.trackingNumber, item.notificationType)}
                                                                    >
                                                                        <Row style={{ alignItems: 'center' }}>
                                                                            <Col span={22}>
                                                                                <div style={{ marginBottom: 2, fontSize: 16, fontWeight: '600' }}>{item.recipientName} - {item.recipientPhoneNumber} ({item.orderType})</div>
                                                                                <div style={{ marginBottom: 2 }}>{item.toAddress}, {item.toTownship.name}, {item.toTownship.division.name} </div>
                                                                                <div >Created Date : {item.createdDate}</div>
                                                                            </Col>
                                                                            <Col span={2} style={{ textAlign: 'center' }}>
                                                                                <Checkbox
                                                                                    checked={item.isSelected}
                                                                                    onChange={(e) => this.onSelectItem(e, item)}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                )}
                                                            />
                                                        </Col>
                                                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            {
                                                                data ?
                                                                    <>
                                                                        <span style={{ marginRight: 10, fontSize: 18 }}>
                                                                            {data.length} of {totalPagination}
                                                                        </span>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            {
                                                                data && data.length && totalPagination && totalPagination === data.length ?
                                                                    <>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Button type="primary" size="large" onClick={() => this.loadMore()} loading={isLoadMore}>
                                                                            Load More
                                                                        </Button>
                                                                    </>
                                                            }
                                                            {/* <Pagination
                                                                defaultCurrent={page}
                                                                pageSize={pageSize}
                                                                total={totalPagination}
                                                                onChange={this.pagination}
                                                                showSizeChanger={false}
                                                            /> */}
                                                        </Col>
                                                    </>
                                                    :
                                                    <></>
                                            }


                                        </Row>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}




export default compose(withRouter)(AWBPage)