import { UploadOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Form, Input, Row, Select, Upload, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { ApiFilter, ApiPostWithFormData } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};


class CoreConfigForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            authorities: [],
            coverages: [],
            userTypes: [],
            fileList: [],
            previewImage: '',
            previewOpen: false,
            isUpdating: false,
            coreType: [],
            uploadFile: null,
            coreValue: null,
            is_key_required: false
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        this.fetchCoreType()
    }


    fetchCoreType = async (pageNumber, value) => {
        try {
            var params = {
                'enumType': 'CORE'
            }
            const response = await ApiFilter(Api.enum_filter, params, this.props)
            if (response) {
                let userTemp = []
                response.map((res) => {
                    userTemp.push({
                        value: res.key,
                        label: res.value,
                    });
                })
                this.setState({
                    coreType: userTemp
                })
            }
        } catch (error) {

        }
        value ?? this.setState({ searchValue: value })
    }


    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true
        })
    };

    handleCancel = () => {
        this.setState({
            previewOpen: false
        })
    }

    saveCoverage = async (values) => {
        this.setState({
            isUpdating: true
        })

        try {
            let formData = new FormData()
            formData.append('key', values.key ? values.key : '');
            formData.append('value', values.value ? values.value : '');
            formData.append('coreType', values.coreType);
            if (values.coreType === 'OC_EXCEL_TEMPLATE') {
                if (values.excel && values.excel.file) {
                    formData.append('file', values.excel.file)
                } else {
                    message.error("Invalid file")
                    return
                }
            }
            const response = await ApiPostWithFormData(Api.core_config, formData, this.props)
            if (response.status === 200) {
                this.props.navigate(CustomPath.core_config)
            }

        } catch (error) {

        }
        this.setState({
            isUpdating: false
        })
    }

    handleChange = (value) => {
        let { is_key_required } = this.state
        if (value === 'OC_SAME_DAY') {
            is_key_required = true
        } else {
            is_key_required = false
        }

        this.setState({
            coreValue: value,
            uploadFile: null,
            is_key_required: is_key_required
        })
    }

    render() {
        const { navigate } = this.props
        const { isUpdating, coreType, uploadFile, coreValue, is_key_required } = this.state

        const props = {
            onRemove: (file) => {
                this.setState({ uploadFile: null })
            },
            beforeUpload: (file) => {
                this.setState({ uploadFile: file })
                return false;
            },
            uploadFile,
        };

        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.core_config}>Core Config</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Core Config Form"}
                            extra={<Button type="primary" onClick={() => navigate(CustomPath.core_config)}>Back</Button>}
                        >
                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                initialValues={{ active: true }}
                                onFinish={this.saveCoverage}>

                                <Form.Item name="coreType" label={"Core Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={this.handleChange}
                                        placeholder="Selece Core Type"
                                        options={coreType}
                                    />
                                </Form.Item>

                                {
                                    coreValue && (coreValue === 'OC_EXCEL_TEMPLATE' || coreValue === 'PARTNER_APPLICATION' || coreValue === 'DRIVER_APPLICATION' || coreValue === 'SHIPPER_APPLICATION') ?
                                        <Form.Item name="excel">
                                            <Upload {...props}>
                                                <Button icon={<UploadOutlined />}>Select File</Button>
                                            </Upload>
                                        </Form.Item>
                                        :
                                        <>
                                            {
                                                is_key_required ?
                                                    <Form.Item name="key" label={"Key"} rules={[{ required: is_key_required, message: '*(requried)' }]}>
                                                        <Input placeholder={"Enter key"} />
                                                    </Form.Item>
                                                    :
                                                    <></>
                                            }

                                            <Form.Item name="value" label={"Value"} rules={[{ required: true, message: '*(requried)' }]}>
                                                <Input placeholder={"Enter value"} />
                                            </Form.Item>

                                        </>
                                }



                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button style={{ marginRight: '20px' }}>Reset</Button>
                                    <Button type="primary" htmlType="submit" loading={isUpdating}>Create</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={1} />
                </Row >
            </>
        );
    }
}

export default compose(withRouter)(CoreConfigForm)