import { compose } from "@reduxjs/toolkit";
import { Button, Col, Image, Modal, Result, Row, Skeleton, Table } from "antd";
import React from "react";
import { TitleLevel3 } from "../../components/general-component";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiFilter, ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";

const empty = require('../../asset/empty-cart.png');


class LoginFailureCachePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            cacheName: null,
            isDataFetching: false,
            isClearingCache: false
        }
    }
    componentDidMount() {
        this.fetchData()
    }

    clearCache = async (key) => {
        const { cacheName } = this.state
        this.setState({
            isClearingCache: true
        })
        try {
            const response = await ApiHandler({ url: Api.redis_cache_remove_by_key, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: key })
            if (response) {
                this.fetchData()
            }
        } catch (error) {
        }
        this.setState({
            isClearingCache: false,
            openModal: false,
            cacheName: null
        })
    }

    fetchData = async () => {
        this.setState({
            isDataFetching: true
        })
        try {
            const response = await ApiHandler({ url: Api.redis_cache_login_failure_rate_logs, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.setState({
                    data: response
                })
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    render() {
        const { data, isDataFetching, isClearingCache, openModal, cacheName } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
                width: 300
            },
            {
                title: 'Rate',
                dataIndex: 'rate',
                key: 'rate',
            },
            {
                title: 'Action',
                width: 60,
                render: (cache) => (
                    <Button type="primary" style={{ backgroundColor: 'red' }} onClick={() => this.clearCache(cache.key)} loading={cacheName === cache ? isClearingCache : false} >Clear</Button>
                ),
            }
        ]
        return (
            <>
                <Modal
                    title="Clear Cache"
                    open={openModal}
                    onOk={() => this.clearCache()}
                    onCancel={() => this.setState({ openModal: false, cacheName: null })}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to clear redis cache?</p>
                </Modal>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <TitleLevel3 label={"Login failure logs"} />
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <>
                                            <Table
                                                pagination={false}
                                                columns={columns}
                                                dataSource={data}
                                                rowClassName={getRowClassName} />
                                        </>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(LoginFailureCachePage)