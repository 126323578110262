import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Image, List, Modal, Row, Skeleton, Space } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { NJVAddButton } from '../../components/core-component';
import { TitleLevel3 } from '../../components/general-component';
import { Colors } from "../../components/theme";
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import BannerItemsCreate from './banner-items-create';
import BannerItemsUpdate from './banner-items-update';

export default class BannerItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isDataFetching: false,
      element: { isCreateOpen: false, isUpdateOpen: false },
      updateItem: null,
      openModal: false,
      isDeleting: false,
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.refresh !== this.props.refresh) {
      this.fetchData()
    }
  }

  fetchData = async () => {
    this.setState({
      isDataFetching: true
    })
    await ApiHandler({ url: Api.banner_items, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.bannerId })
      .then((response) => {
        this.setState({
          data: response,
          isDataFetching: false
        })
      }).catch(error => { })
  }

  handleCreateClose = () => {
    this.setState({ element: { isCreateOpen: false } })
    this.fetchData();
  }

  handleUpdateClose = () => {
    this.setState({ element: { isUpdateOpen: false } })
    this.fetchData();
  }

  deleteBannerItem = async () => {
    const { bannerItemId } = this.state
    this.setState({ isDeleting: true })
    await ApiHandler({ url: Api.banner_items, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: bannerItemId })
        .then(() => {

        }).catch(() => { })
        .finally(() => {
            this.setState({
                openModal: false,
                bannerItemId: 0,
                isDeleting: false
            });
        })

    this.fetchData()
}

  render() {
    const { data, isDataFetching, element, updateItem, openModal, isDeleting } = this.state;

    return (
      <>
        <Modal
          title="Delete"
          open={openModal}
          onOk={() => this.deleteBannerItem()}
          onCancel={() => this.setState({ openModal: false })}
          confirmLoading={isDeleting}
          okText="Confirm"
          cancelText="Cancel"
        >
          <p>Are you sure to delete?</p>
        </Modal>
        {
          element.isCreateOpen ? <BannerItemsCreate bannerId={this.props.bannerId} handleClose={this.handleCreateClose} /> : element.isUpdateOpen ? <BannerItemsUpdate item={updateItem} handleClose={this.handleUpdateClose} /> : (
            <>
              <Row style={{ marginBottom: "20px" }}>
                <Col span={1} />
                <Col span={11} style={{ display: 'flex', alignItems: 'center' }}>
                  <TitleLevel3 label={"Loyalty Banner Items"} />
                </Col>
                <Col span={11} style={{ textAlign: 'right' }}>
                  <NJVAddButton text="Create" handleClick={() => this.setState({ element: { isCreateOpen: true } })} />
                </Col>
                <Col span={1} />
              </Row>
              <Row justify='center'>
                <Col span={1}></Col>
                <Col span={22}>
                  {
                    isDataFetching ?
                      <Skeleton active />
                      :
                      <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                          <Card
                            style={{
                              paddingBottom: 10,
                              paddingTop: 10,
                              marginBottom: 10,
                              borderRadius: 20,
                              width: '100%',
                              cursor: 'pointer',
                            }}
                            bordered={false}
                          >
                            <Row style={{ width: '100%' }}>
                              <Col span={24}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                  <Image src={item.photoUrl} width={200} />
                                  <div style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <span
                                        style={{
                                          fontSize: 17,
                                          fontWeight: '600'
                                        }}
                                      >
                                        <span style={{ color: Colors.primary }}>Name</span> - {item.name}
                                        <br />
                                        {
                                          !item.link || item.link === "undefined" || item.link === 'null' ? <></> : (
                                            <>
                                              <span style={{ color: Colors.primary }}>Link</span> - {item.link}
                                              <br />
                                            </>
                                          )
                                        }
                                        <span style={{ color: Colors.primary }}>Created Date</span> - {dayjs(item.createdDate).format('DD/MM/YYYY HH:mm:ss A')}
                                      </span>
                                      <Space>
                                        <Button size={'middle'} type="primary" shape="circle" onClick={() => {
                                          this.setState({ element: { isUpdateOpen: true }, updateItem: item })
                                        }}><EditOutlined /></Button>
                                        <Button size={'middle'} type="primary" shape="circle" onClick={() => this.setState({ bannerItemId: item.id, openModal: true })}><DeleteOutlined /></Button>
                                      </Space>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        )}
                      />
                  }
                </Col>
                <Col span={1}></Col>
              </Row>
            </>
          )
        }
      </>
    )
  }
}
